#root {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
}

.App {
	width: 100%;
	height: 100%;
	background-color: cornflowerblue;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Helvetica, sans-serif;
}

p {
	margin: 0;
}

#baseInput {
	width: 50px;
	text-align: center;
}

table {
	border-collapse: collapse;
	cursor: crosshair;
	filter: drop-shadow(0px 10px 10px rgb(74, 105, 161));
}

th,
td {
	width: 100px;
	/* text-align: center; */
	border: 1px solid;
	background-color: lightgrey;
}

td {
	/* display: flex; */
	background-color: whitesmoke;
}

.cell {
	display: flex;
	gap: 2px;
	align-items: center;
}

.cell-tone {
}
.tone-toggle {
	width: 1rem;
	height: 1rem;
	color: dodgerblue;
	vertical-align: middle;
	-webkit-appearance: none;
	background: none;
	border: 0;
	outline: 0;
	flex-grow: 0;
	border-radius: 50%;
	background-color: #FFFFFF;
	transition: background 300ms;
	cursor: pointer;
}
.tone-toggle::before {
	content: "";
	color: transparent;
	display: block;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	border: 0;
	background-color: transparent;
	background-size: contain;
	box-shadow: inset 0 0 0 1px #CCD3D8;
}
.tone-toggle:checked {
	background-color: cornflowerblue;
}
.tone-toggle:checked::before {
	box-shadow: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.toggleWrap {
	display: flex;
	justify-content: space-evenly;
}

.toggleButton {
	border-radius: 4px;
	font-size: 12px;
	background-color: aliceblue;
	padding: 4px 4px;
	margin: 4px 0px;
	cursor: pointer;
	opacity: 1;
	transition: 0.3s;
}

.toggleButton.selected {
	background-color: coral;
}

.toggleButton:hover {
	background-color: rgb(255, 167, 135);
	transition: 0.5s;
}

.toggleButton:active {
	background-color: rgb(255, 89, 28);
}
